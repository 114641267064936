import { useContext, useEffect, useState } from "react"
import Icon from "../components/icon/Icon"
import styles from "./Payment.module.scss"
import youwillgetPng from "./assets/images/check.png"
import trialPng from "./assets/images/gift.png"
import paymentApi, { PaymentPlanDTO } from "../api/payment.api"
import { UserContext } from "../login/context/UserContext"
import Button from "../components/Button"


const youWillGets = [
    "Publication/membership/award analysis",
    "End-to-end petition letter generation",
    "Unlimited access to immigration lawyer customized chatbot",
    "Case tracking and VISA bulletin tool",
    "Smart form filling",
    "Unlimited NIW/EB1A qualification evaluation"
]

export default (props: {close?: () => void}) => {

    const [plans, setPlans] = useState<PaymentPlanDTO[]>()
    const {
        close,
    } = props

    useEffect(() => {
        paymentApi.plans().then(setPlans)

        const keypressHandle = (e: any) => {
            if(e.key === 'Escape'){
                close && close()
            }
        } 

        window.addEventListener("keydown", keypressHandle, true)

        return () => {
            window.removeEventListener("keydown", keypressHandle)
        }

        
    }, [])

    return (
        <div id="payment" className={styles['payment-wrapper']}>
            <div className={styles['payment']}>
                <div className={styles['payment__header']}>
                    <div className={styles['payment__title']}>Welcome to Leazy!</div>
                    <div className={styles['payment__subtitle']}>Unlock the most powerful AI legal assistant. Choose how often you would like to be billed. You can cancel at anytime.</div>
                </div>
                <div className={styles['payment__divider']}></div>
                <div className={styles['payment__body']}>
                    <div className={styles['payment__youwillget']}>
                        <div className={styles['payment__youwillget__title']}>You will get</div>
                        <div className={styles['payment__youwillget__list']}>
                            {youWillGets.map(item => 
                                <div className={styles['payment__youwillget__item']}>
                                    <Icon src={youwillgetPng} size={20}/>
                                    {item}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles['payment__divider']}></div>
                <div className={styles['payment__footer']}>
                            {plans?.map(plan => <PaymentPlan plan={plan} />)}
                </div>
            </div>
        </div>
    )
}


const PaymentPlan = (props: {plan: PaymentPlanDTO}) => {

    const {subscription} = useContext(UserContext)
    const [loading, setLoading] = useState<boolean>()

    const {
        plan
    } = props

    const renderInterval = () => {
        return plan.recurring_interval === "month"?"MONTHLY":"YEARLY"
    }

    const renderAmount = () => {
        const unit = plan.currency === "usd"?"$":"?"
        return `${unit}${plan.unit_amount}`
    }

    const renderBtnLabel = () => {
        if(subscription?.status === "TRIALING"){
            return "Subscribe"
        }else{
            return "Start Free Trial"
        }
    }

    const doPay = async () => {

        if(loading) return

        setLoading(true)
        try{
            if(subscription?.status === "UNSUBSCRIBED"){
                const session = await paymentApi.createCheckoutSession(plan.price_id);
                if(session.checkout_url){
                    window.open(session.checkout_url, "_blank")
                }
            }else{
                const session = await paymentApi.createPortalSession();
                if(session.portal_url){
                    window.open(session.portal_url, "_blank")
                }
            }
        }finally{
            setLoading(false)
        }
        
    }

    return (
        <div className={styles["payment-card"]}>
            <div className={styles["payment-card__body"]}>
                <div className={styles["payment-card__title"]}>
                {renderInterval()}
                </div>
                <div className={styles["payment-card__price"]}>
                {renderAmount()}
                </div>
                <div className={styles["payment-card__period"]}>
                billed per {plan.recurring_interval}
                </div>
                <div className={styles["payment-card__trial"]}>
                    <Icon src={trialPng} size={16}></Icon>
                    {plan.free_trial_days}-day free trial
                </div>
            </div>
            <div className={styles["payment-card__footer"]}>
                <Button.Primary className={styles["payment-card__btn"]} loading={loading} onClick={doPay} width={"100%"}>{renderBtnLabel()}</Button.Primary>
            </div>
        </div>
    )
}

