
import { ConfigProvider, Flex } from "antd"
import Button from "../components/Button"
import { useDrawer } from "../components/drawer/useDrawer"
import Form from "../components/form/Form"
import { useFormInstance } from "../components/form/FormContext"
import Icon from "../components/icon/Icon"
import styles from "./SmartTools.module.scss"

import goBackSvg from "./assets/images/goback.svg"
import FormValidators from "../components/form/FormValidators"
import Env from "../../Env"
import { ProofDoc } from "../api/FormFilling.api"
import FileUpload, { Progress } from "./FileUpload"
import { useContext, useEffect, useState } from "react"
import smarttoolsApi, { PublicationToolResponse } from "./smarttools.api"
import MarkdownPreview from '@uiw/react-markdown-preview';
import dayjs from "dayjs"
import useBroadcast from "../components/broadcast/useBroadcast"
import SmartArticle from "./components/SmartArticle"
import spaIcon from "./assets/images/icon/Scroll.png"
import gspaIcon from "./assets/images/icon/GraduationCap.png"
import mcIcon from "./assets/images/icon/MicrophoneStage.png"
import rlIcon from "./assets/images/icon/EnvelopeSimple.png"
import hsaIcon from "./assets/images/icon/Cardholder.png"
import maIcon from "./assets/images/icon/tdesign_member-filled.png"
import aaIcon from "./assets/images/icon/Medal.png"
import { BroadcastContext } from "../components/broadcast/BroadcastContext"


const toolOptions = [
    {
        label: "Single Paper Analysis",
        icon: spaIcon,
        component: () => <SinglePagerAnalysis/>
    },
    {
        label: "Google Scholar Page Analysis",
        icon: gspaIcon,
        component: () => <GoogleScholarPageAnalysis/>
    },
    {
        label: "Media Coverage",
        icon: mcIcon,
        component: () => <MediaCoverage/>
    },
    {
        label: "Recommendation Letter(coming soon ...)",
        icon: rlIcon,
        component: () => <RecommendationLetter/>,
        disable: true
    },
    {
        label: "High Salary Analysis",
        icon: hsaIcon,
        component: () => <HighSalaryAnalysis/>,
    },
    {
        label: "Award Analysis",
        icon: aaIcon,
        component: () => <AwardAnalysis/>
    },
    {
        label: "Membership Analysis",
        icon: maIcon,
        component: () => <MembershipAnalysis/>
    }
]

export default () => {

    const {render, show, close} = useDrawer({width: "calc(100vw - 80px)", mask: false, keyboard: true, push: false})

    const showTool = (option: any) => {
        if(option.disable) return
        show(
            <div className={styles["tool"]}>
                <ToolHeader title={option.label} onGoback={close}/>
                <option.component/>
            </div>
        )
    }

    const toolRender = (option: any) => {
        return (
            <div className={[styles["smart-tools__item"], option.disable?styles["smart-tools__item_disable"]:styles["smart-tools__item_normal"]].join(" ")} onClick={() => showTool(option)}>
                <div className={styles["smart-tools__item__icon"]}><Icon src={option.icon} size={24}></Icon></div>{option.label}
            </div>
        )
    }

    return (
        <div className={styles["smart-tools"]}>
            <div className={styles["smart-tools__header"]}>
            Smart Tools
            </div>
            <div className={styles["smart-tools__content"]}>
                {toolOptions.map(toolRender)}
            </div>
            {render}
        </div>
    )
}

const ToolHeader = (props: {title: string, onGoback: () => void}) => {
    return (
        <div className={styles['tool__header']}>
            <Icon src={goBackSvg} size={20} onClick={props.onGoback}/>
            {props.title}
        </div>
    )
}


const SinglePagerAnalysis = () => {

    const option = toolOptions[0]
    const formInstance = useFormInstance()
    const [percent, setPercent] = useState<number>(1)
    const [res, setRes] = useState<PublicationToolResponse>()
    const {error, render} = useBroadcast()


    const submitHandle = async (value: any) =>{
        // console.log("submit, value: ", value)
        const searchTask = await smarttoolsApi.researchTools({
            tool_name: "publication_tool",
            tool_parameters: {
                applicant_name: value['applicant_name'],
                applicant_field: value['applicant_field'],
                paper_file_id: value['paper_file_id'][0].id
            }
        }) as PublicationToolResponse
        const taskId = searchTask['task_id']
        setPercent(1)
        setRes(searchTask)
        await new Promise((resolve, reject) => {
            const schedule = setInterval(async () => {
                const res = await smarttoolsApi.checkTask(taskId)
                setRes(res)

                switch(res.status){
                    case "SUBMITTED":
                    case "RUNNING":
                        setPercent(current => Math.min(current + 1, 99))
                        break;
                    case "SUCCEEDED":
                        clearInterval(schedule)
                        resolve(true)
                        break
                    case "FAILED":
                        clearInterval(schedule)
                        error({content: res.status})
                        resolve(true)
                }
                
            }, 1000);
        })
    }

    const exhibitsRender = () => {
        
        if(!(res?.exhibits)){
            return <></>
        }

        return (
            <Flex gap={16} vertical>
                <h2>Appendix - Exhibit List</h2>   
                {
                    res.exhibits.map(exhibit => {
                        return (
                            <p>
                                {exhibit.text}
                                {exhibit.sources.map(source => <a href={source.url}>{source.url_text}</a>)}
                            </p>
                        )
                    })
                }
            </Flex>
            
            
        )
    }

    const consoleRender = () => {
        if(!res) return (
            <section>
                Please complete the information on the left to ensure the most accurate results.
            </section>
        )
        if(res.status !== "SUCCEEDED"){
            return (
                <Flex gap={12} vertical>
                    Please wait patiently. Your results will be ready in 30 seconds to 1 minute.
                    <Progress percent={percent}/>
                </Flex>
            )
        }else{
            return (
                <SmartArticle article={res}/>
            )
        }
    }

    
    return (
        <div className={styles['tool__body']}>
            <div className={styles['tool__body__scrollview']}>
                <div className={styles['tool__body__form']}>
                    <Form.Form instance={formInstance} onSubmit={submitHandle}>
                        <Form.Item label="Upload full article PDF" name="paper_file_id" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <FileUpload/>
                        </Form.Item>
                        <Form.Item label="Applicant’s name" name="applicant_name" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the name"/>
                        </Form.Item>
                        <Form.Item label="Applicant’s field" name="applicant_field" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the field"/>
                        </Form.Item>
                        <Form.Item nowrap label="" name="">
                            <Flex justify="flex-end">
                                <Button.TransparentBg width={128}>Clear all</Button.TransparentBg>
                                <Form.Submit width={128}>Generate</Form.Submit>
                            </Flex>
                        </Form.Item>
                    </Form.Form>
                </div>
            </div>
            <div className={styles['tool__body__scrollview']} style={{flex: 1}}>
                <div className={styles['tool__body__console']}>
                    <div className={styles['publication']}>
                        {consoleRender()}
                    </div>
                </div>
            </div>
            {render}
        </div>
    )
}

const GoogleScholarPageAnalysis = () => {
    const option = toolOptions[1]
    const formInstance = useFormInstance()
    const [percent, setPercent] = useState<number>(1)
    const [res, setRes] = useState<PublicationToolResponse>()
    const {error} = useBroadcast()

    const submitHandle = async (value: any) =>{
        const searchTask = await smarttoolsApi.researchTools({
            tool_name: "google_scholar_page_tool",
            tool_parameters: value
        }) as PublicationToolResponse
        const taskId = searchTask['task_id']
        setPercent(1)
        setRes(searchTask)
        new Promise((resolve, reject) => {
            const schedule = setInterval(async () => {
                const res = await smarttoolsApi.checkTask(taskId)
                setRes(res)
                
                switch(res.status){
                    case "SUBMITTED":
                    case "RUNNING":
                        setPercent(current => Math.min(current + 1, 99))
                        break;
                    case "SUCCEEDED":
                        clearInterval(schedule)
                        resolve(true)
                        break
                    case "FAILED":
                        error({content: res.status})
                        resolve(true)
                }
                
            }, 1000);
        })
    }
    
    const consoleRender = () => {
        if(!res) return (
            <section>
                Please complete the information on the left to ensure the most accurate results.
            </section>
        )
        if(res.status !== "SUCCEEDED"){
            return (
                <Flex gap={12} vertical>
                    Please wait patiently. Your results will be ready in 30 seconds to 1 minute.
                    <Progress percent={percent}/>
                </Flex>
            )
        }else{
            return (
                <SmartArticle article={res}></SmartArticle>
            )
        }
    }

    return (
        <div className={styles['tool__body']}>
            <div className={styles['tool__body__scrollview']}>
                <div className={styles['tool__body__form']}>
                    <Form.Form instance={formInstance} onSubmit={submitHandle}>
                        <Form.Item label="Google scholar author page link" name="google_scholar_page_link" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the link"/>
                        </Form.Item>
                        <Form.Item label="Applicant’s name" name="applicant_name" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the name"/>
                        </Form.Item>
                        <Form.Item label="Applicant’s field" name="applicant_field" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the field"/>
                        </Form.Item>
                        <Form.Item nowrap label="" name="">
                            <Flex justify="flex-end">
                                <Button.TransparentBg width={128}>Clear all</Button.TransparentBg>
                                <Form.Submit width={128}>Generate</Form.Submit>
                            </Flex>
                        </Form.Item>
                    </Form.Form>
                </div>
            </div>
            <div className={styles['tool__body__scrollview']} style={{flex: 1}}>
                <div className={styles['tool__body__console']}>
                    <div className={styles['google-scholar']}>
                        {consoleRender()}
                    </div>
                </div>
            </div>
        </div>
    )
}

const MediaCoverage = () => {
    const option = toolOptions[2]
    const formInstance = useFormInstance()
    const [percent, setPercent] = useState<number>(1)
    const [res, setRes] = useState<PublicationToolResponse>()
    const {error} = useBroadcast()

    const submitHandle = async (value: any) =>{
        const searchTask = await smarttoolsApi.researchTools({
            tool_name: "media_tool",
            tool_parameters: value
        }) as PublicationToolResponse
        const taskId = searchTask['task_id']
        setPercent(1)
        setRes(searchTask)
        new Promise((resolve, reject) => {
            const schedule = setInterval(async () => {
                const res = await smarttoolsApi.checkTask(taskId)
                setRes(res)
                
                switch(res.status){
                    case "SUBMITTED":
                    case "RUNNING":
                        setPercent(current => Math.min(current + 1, 99))
                        break;
                    case "SUCCEEDED":
                        clearInterval(schedule)
                        resolve(true)
                        break
                    case "FAILED":
                        error({content: res.status})
                        resolve(true)
                }
                
            }, 1000);
        })
    }

    const consoleRender = () => {
        if(!res) return (
            <section>
                To ensure the most accurate results, please provide as much information as possible.
            </section>
        )
        if(res.status !== "SUCCEEDED"){
            return (
                <Flex gap={12} vertical>
                    Please wait patiently. Your results will be ready in 30 seconds to 1 minute.
                    <Progress percent={percent}/>
                </Flex>
            )
        }else{
            return (
                <SmartArticle article={res}></SmartArticle>
            )
        }
    }
    

    return (
        <div className={styles['tool__body']}>
            <div className={styles['tool__body__scrollview']}>
                <div className={styles['tool__body__form']}>
                    <Form.Form instance={formInstance} onSubmit={submitHandle}>
                        <Form.Item label="Media name" name="name" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the name"/>
                        </Form.Item>
                        <Form.Item label="Link" name="media_url" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the link"/>
                        </Form.Item>
                        <Form.Item label="Year" name="year" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.DatePicker minDate={dayjs('1900')} picker="year" placeholder="Select one" />
                        </Form.Item>
                        <Form.Item label="Applicant’s name" name="applicant_name" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the name"/>
                        </Form.Item>
                        <Form.Item label="Applicant’s field" name="applicant_field" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the field"/>
                        </Form.Item>
                        <Form.Item nowrap label="" name="">
                            <Flex justify="flex-end">
                                <Button.TransparentBg width={128}>Clear all</Button.TransparentBg>
                                <Form.Submit width={128}>Generate</Form.Submit>
                            </Flex>
                        </Form.Item>
                    </Form.Form>
                </div>
            </div>
            <div className={styles['tool__body__scrollview']} style={{flex: 1}}>
                <div className={styles['tool__body__console']}>
                    <div className={styles['media-coverage']}>
                            {consoleRender()}
                    </div>
                </div>
            </div>
        </div>
    )
}

const RecommendationLetter = () => {
    const option = toolOptions[3]
    const formInstance = useFormInstance()
    return (
        <div className={styles['tool__body']}>
            <div className={styles['tool__body__scrollview']}>
                <div className={styles['tool__body__form']}>
                <Form.Form instance={formInstance}>
                        <Form.Item label="Please upload the completed recommendation letter questionnaire for your client, so we can extract the necessary information and complete the sections below." name="">
                            <FileUpload />
                        </Form.Item>
                        <Form.Item label="Recommender’s name" name="">
                            <Form.Input placeholder="Please enter the name"/>
                        </Form.Item>
                        <Form.Item label="Recommender’s education background" name="">
                            <Form.Input placeholder="Please enter the education background"/>
                        </Form.Item>
                        <Form.Item label="Current job title" name="">
                            <Form.Input placeholder="Please enter the job title"/>
                        </Form.Item>
                        <Form.Item label="Previous employment history (if known)" name="">
                            <Form.Input placeholder="Please enter the employment history"/>
                        </Form.Item>
                        <Form.Item label="Awards and honors (if applicable)" name="">
                            <Form.Input placeholder="Please enter the awards and honors"/>
                        </Form.Item>
                        <Form.Item label="Publications (if applicable)" name="">
                            <Form.Input placeholder="Please enter the awards and honors"/>
                        </Form.Item>
                        <Form.Item label="Other achievements" name="">
                            <Form.TextArea placeholder="Other achievements indicating Reference’s professional status within your field of endeavor."/>
                        </Form.Item>
                        <Form.Item label="Previous/Current collaboration" name="">
                            <Form.TextArea placeholder="Please explain how you came to know the applicant and provide detailed information about your previous or current collaboration (if applicable)."/>
                        </Form.Item>
                        <Form.Item label="Contributions" name="">
                            <Form.TextArea placeholder="Please provide a detailed description of the applicant's current key role or research, highlighting original and significant contributions in their field, with specific examples if possible."/>
                        </Form.Item>
                        <Form.Item label="Professional standing" name="">
                            <Form.TextArea placeholder="From your perspective as a reference, please furnish 3-4 sentences assessing the applicant's current professional standing within their field of expertise."/>
                        </Form.Item>
                        
                        <Form.Item nowrap label="" name="">
                            <Flex justify="flex-end">
                                <Button.TransparentBg width={128}>Clear all</Button.TransparentBg>
                                <Form.Submit width={128}>Generate</Form.Submit>
                            </Flex>
                        </Form.Item>
                    </Form.Form>
                </div>
            </div>
            <div className={styles['tool__body__scrollview']} style={{flex: 1}}>
                <div className={styles['tool__body__console']}>
                To ensure the most accurate results, please provide as much information as possible.
                </div>
            </div>
        </div>
    )
}

const HighSalaryAnalysis = () => {
    const option = toolOptions[4]
    const formInstance = useFormInstance()

    const [percent, setPercent] = useState<number>(1)
    const [res, setRes] = useState<PublicationToolResponse>()
    const {error} = useContext(BroadcastContext)

    const submitHandle = async (value: any) =>{
        const searchTask = await smarttoolsApi.researchTools({
            tool_name: "salary_tool",
            tool_parameters: value
        }) as PublicationToolResponse
        const taskId = searchTask['task_id']
        setPercent(1)
        setRes(searchTask)
        await new Promise((resolve, reject) => {
            const schedule = setInterval(async () => {
                const res = await smarttoolsApi.checkTask(taskId)
                setRes(res)
                
                switch(res.status){
                    case "SUBMITTED":
                    case "RUNNING":
                        setPercent(current => Math.min(current + 1, 99))
                        break;
                    case "SUCCEEDED":
                        clearInterval(schedule)
                        resolve(true)
                        break
                    case "FAILED":
                        error({content: res.status})
                        resolve(true)
                        clearInterval(schedule)
                }
                
            }, 1000);
        })
    }

    const consoleRender = () => {
        if(!res) return (
            <section>
                To ensure the most accurate results, please provide as much information as possible.
            </section>
        )
        if(res.status !== "SUCCEEDED"){
            return (
                <Flex gap={12} vertical>
                    Please complete the information on the left to ensure the most accurate results.
                    <Progress percent={percent}/>
                </Flex>
            )
        }else{
            return (
                <>
                {
                    res.options?.map(article => (
                        <SmartArticle article={article}></SmartArticle>
                    ))
                }
                </>
            )
        }
        
    }

    return (
        <div className={styles['tool__body']}>
            <div className={styles['tool__body__scrollview']}>
                <div className={styles['tool__body__form']}>
                    <Form.Form instance={formInstance} onSubmit={submitHandle}>
                        <Form.Item label="Salary" name="salary_in_usd" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="$"/>
                        </Form.Item>
                        <Form.Item label="Job title" name="job_title" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the job title"/>
                        </Form.Item>
                        <Form.Item label="Country (US)" name="country" >
                            <Form.Input placeholder="US" value={"US"} disabled={true}/>
                        </Form.Item>
                        <Form.Item label="State (US)" name="state" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="WA"/>
                        </Form.Item>
                        <Form.Item label="Applicant’s name" name="applicant_name" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the name"/>
                        </Form.Item>
                        <Form.Item label="Applicant’s field" name="applicant_field" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the field"/>
                        </Form.Item>
                        <Form.Item nowrap label="" name="">
                            <Flex justify="flex-end">
                                <Button.TransparentBg width={128}>Clear all</Button.TransparentBg>
                                <Form.Submit width={128}>Generate</Form.Submit>
                            </Flex>
                        </Form.Item>
                    </Form.Form>
                </div>
            </div>
            <div className={styles['tool__body__scrollview']} style={{flex: 1}}>
                <div className={styles['tool__body__console']}>
                    <div className={styles['high-salary']}>
                        {consoleRender()}
                    </div>
                </div>
            </div>
        </div>
    )
}

const AwardAnalysis = () => {
    const option = toolOptions[5]
    const formInstance = useFormInstance()
    const [percent, setPercent] = useState<number>(1)
    const [res, setRes] = useState<PublicationToolResponse>()

    const submitHandle = async (value: any) =>{
        const searchTask = await smarttoolsApi.researchTools({
            tool_name: "award_tool",
            tool_parameters: value
        }) as PublicationToolResponse
        const taskId = searchTask['task_id']
        setPercent(1)
        setRes(searchTask)
        new Promise((resolve, reject) => {
            const schedule = setInterval(async () => {
                const res = await smarttoolsApi.checkTask(taskId)
                setRes(res)

                if(res.status !== "SUCCEEDED"){
                    setPercent(current => Math.min(current + 1, 99))
                }else{
                    clearInterval(schedule)
                    resolve(true)
                }
                
            }, 1000);
        })
    }
    
    const consoleRender = () => {
        if(!res) return (
            <section>
                Please complete the information on the left to ensure the most accurate results.
            </section>
        )
        if(res.status !== "SUCCEEDED"){
            return (
                <Flex gap={12} vertical>
                    Please wait patiently. Your results will be ready in 30 seconds to 1 minute.
                    <Progress percent={percent}/>
                </Flex>
            )
        }else{
            return (
                <SmartArticle article={res}></SmartArticle>
            )
        }
    }
    return (
        <div className={styles['tool__body']}>
            <div className={styles['tool__body__scrollview']}>
                <div className={styles['tool__body__form']}>
                    <Form.Form instance={formInstance} onSubmit={submitHandle}>
                        <Form.Item label="Award name" name="name" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the job title"/>
                        </Form.Item>
                        <Form.Item label="Issuing authority" name="issuing_authority" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the issuing authority"/>
                        </Form.Item>
                        <Form.Item label="Issuing year" name="year" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.DatePicker minDate={dayjs('1900')} picker="year" placeholder="Select one" />
                        </Form.Item>
                        <Form.Item label="Applicant’s name" name="applicant_name" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the name"/>
                        </Form.Item>
                        <Form.Item label="Applicant’s field" name="applicant_field" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the field"/>
                        </Form.Item>
                        <Form.Item nowrap label="" name="">
                            <Flex justify="flex-end">
                                <Button.TransparentBg width={128}>Clear all</Button.TransparentBg>
                                <Form.Submit width={128}>Generate</Form.Submit>
                            </Flex>
                        </Form.Item>
                    </Form.Form>
                </div>
            </div>
            <div className={styles['tool__body__scrollview']} style={{flex: 1}}>
                <div className={styles['tool__body__console']}>
                    <div className={styles['awards']}>
                        {consoleRender()}
                    </div>
                </div>
            </div>
        </div>
    )
}

const MembershipAnalysis = () => {
    const option = toolOptions[6]
    const formInstance = useFormInstance()

    const [percent, setPercent] = useState<number>(1)
    const [res, setRes] = useState<PublicationToolResponse>()

    const submitHandle = async (value: any) =>{
        const searchTask = await smarttoolsApi.researchTools({
            tool_name: "membership_tool",
            tool_parameters: value
        }) as PublicationToolResponse
        const taskId = searchTask['task_id']
        setPercent(1)
        setRes(searchTask)
        new Promise((resolve, reject) => {
            const schedule = setInterval(async () => {
                const res = await smarttoolsApi.checkTask(taskId)
                setRes(res)

                if(res.status !== "SUCCEEDED"){
                    setPercent(current => Math.min(current + 1, 99))
                }else{
                    clearInterval(schedule)
                    resolve(true)
                }
                
            }, 1000);
        })
    }
    
    const consoleRender = () => {
        if(!res) return (
            <section>
                Please complete the information on the left to ensure the most accurate results.
            </section>
        )
        if(res.status !== "SUCCEEDED"){
            return (
                <Flex gap={12} vertical>
                    Please wait patiently. Your results will be ready in 30 seconds to 1 minute.
                    <Progress percent={percent}/>
                </Flex>
            )
        }else{
            return (
                <SmartArticle article={res}></SmartArticle>
            )
        }
    }

    return (
        <div className={styles['tool__body']}>
            <div className={styles['tool__body__scrollview']}>
                <div className={styles['tool__body__form']}>
                    <Form.Form instance={formInstance} onSubmit={submitHandle}>
                        <Form.Item label="Membership Name" name="name" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the job title"/>
                        </Form.Item>
                        <Form.Item label="Position" name="position" >
                            <Form.Input placeholder="Please enter the issuing authority"/>
                        </Form.Item>
                        <Form.Item label="Start year" name="start_year" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.DatePicker minDate={dayjs('1900')} picker="year" placeholder="Select one" />
                        </Form.Item>
                        <Form.Item label="End year" name="end_year" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.DatePicker minDate={dayjs('1900')} picker="year" placeholder="Select one" />
                        </Form.Item>
                        <Form.Item label="Applicant’s name" name="applicant_name" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the name"/>
                        </Form.Item>
                        <Form.Item label="Applicant’s field" name="applicant_field" required rules={[{validate: FormValidators.required, msg: " "}]}>
                            <Form.Input placeholder="Please enter the field"/>
                        </Form.Item>
                        <Form.Item nowrap label="" name="">
                            <Flex justify="flex-end">
                                <Button.TransparentBg width={128}>Clear all</Button.TransparentBg>
                                <Form.Submit width={128}>Generate</Form.Submit>
                            </Flex>
                        </Form.Item>
                    </Form.Form>
                </div>
            </div>
            <div className={styles['tool__body__scrollview']} style={{flex: 1}}>
                <div className={styles['tool__body__console']}>
                    <div className={styles['membership']}>
                        {consoleRender()}
                    </div>
                </div>
            </div>
        </div>
    )
}

