import { PropsWithChildren, useContext, useEffect, useState } from "react"
import { UserContext } from "./context/UserContext"
import loginApi, { GuideTourStatus, UserDTO } from "../api/login.api"
import useToken from "../libs/useToken"
import { Navigate, useNavigate, useNavigation } from "react-router-dom"
import paymentApi, { SubscriptionDTO } from "../api/payment.api"


export default (props: PropsWithChildren<{}>) => {

    const {getToken} = useToken()
    const [user, setUser] = useState<UserDTO | undefined>()
    const [subscription, setSubscription] = useState<SubscriptionDTO|undefined>()
    const navigate = useNavigate()
    
    // const setUser = (user: UserDTO | undefined) => {
    //     setUser(user)
    // }
    const changeTourStatus =  (status: GuideTourStatus, flag: boolean) => {
        user && setUser({
            ...user,
            guide_tour_status: {
                ...(user?.guide_tour_status),
                [status]: flag
            }
        })
    }

    useEffect(() => {
        getToken() && loginApi.me().then(setUser).catch((e) => {
            navigate("/login")
        })
    }, [])

    useEffect(() => {
        if(!user){
            paymentApi.subscription().then(setSubscription)
            // setSubscription({
            //     "current_period_end": "2025-12-31T07:59:16+00:00",
            //     "current_plan": {
            //         "currency": "usd",
            //         "free_trial_days": 30,
            //         "price_id": "price_1QXVJ6FktxhATjbNP82zdmGy",
            //         "recurring_interval": "year",
            //         "recurring_interval_count": 1,
            //         "unit_amount": 1
            //     },
            //     "status": "TRIALING"
            // })
        }
    }, [user])

    if(getToken()){
        return (
            <UserContext.Provider value={{user, setUser, changeTourStatus, subscription}}>
                {props.children}
            </UserContext.Provider>
        )
    }

    return (<Navigate to={"/login"}></Navigate>)

    
    
}