import axios from "axios"
import { HttpResponse, checkCode } from "../libs/http"

export type ToolName = "publication_tool" | "google_scholar_page_tool" | "award_tool" | "membership_tool" | "media_tool" | "salary_tool"

export interface ResearchToolsRequest{
    tool_name: ToolName
    tool_parameters: PublicationToolParams
}

export interface PublicationToolParams{
    paper_file_id: string
    applicant_name: string
    applicant_field: string
}

export interface ResearchToolsResponse{
    task_id: string
    status: ResearchStatus
}

export type ResearchStatus = "SUCCEEDED" | "SUBMITTED" | "FAILED" | "RUNNING"

export interface PublicationToolResponse extends ResearchToolsResponse{
    sections: Section[]
    exhibits: Exhibit[]
    options?: [
        {
            sections: Section[]
            exhibits: Exhibit[]
        }
    ]
}

export interface Section {
    section_type: string //"text" | "image"
    section_title: string
    criteria: string
    content: string
    url?: string
}

export interface Exhibit{
    index: number
    label: string
    sources: ExhibitSource[]
    text: string
}

export interface ExhibitSource{
    type: string
    url: string
    url_text: string
}

const researchTools = async (request: ResearchToolsRequest) => {

    const res = (await axios.post(
        `/api/research_tools`,
        request
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data
}

const checkTask = async (taskId: string): Promise<PublicationToolResponse> =>{
    const res = (await axios.get(
        `/api/research_tools/${taskId}`,
    )).data as HttpResponse<PublicationToolResponse>
    return res.data
}


export default {
    researchTools,
    checkTask
}