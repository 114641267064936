
import axios from "axios";
import { HttpResponse, checkCode } from "../libs/http";

export interface SubscriptionDTO{
    cancel_at?: string
    cancel_at_period_end: true
    current_period_end?: string
    current_plan?: PaymentPlanDTO
    status: SubscriptionStatus
}

export type SubscriptionStatus = "UNSUBSCRIBED" | "TRIALING" | "ACTIVE" | "INVALID" | "CANCELED"

export interface PaymentPlanDTO{
  currency: string
  free_trial_days: number
  price_id: string
  recurring_interval: string
  recurring_interval_count: number
  unit_amount: number
}

export interface PaymentCheckoutSessionDTO{
  checkout_url: string
}

export interface PaymentPortalSessionDTO{
  portal_url: string
}

const subscription = async ():Promise<SubscriptionDTO> => {
    const axiosRes = (await axios.get(`/api/payment/subscription`))
    if(axiosRes.status !== 200){
      throw new Error("" + axiosRes.status)
    }
    const response = axiosRes.data as HttpResponse<SubscriptionDTO>;
    checkCode(response)

    return response.data;
}

const plans = async ():Promise<PaymentPlanDTO[]> => {
    const axiosRes = (await axios.get(`/api/payment/plans`))
    if(axiosRes.status !== 200){
      throw new Error("" + axiosRes.status)
    }
    const response = axiosRes.data as HttpResponse<{plans: PaymentPlanDTO[]}>;
    checkCode(response)

    return response.data.plans
    
}

const createCheckoutSession = async (price_id: string):Promise<PaymentCheckoutSessionDTO> => {
  const axiosRes = (await axios.post(`/api/payment/create_checkout_session`,  {price_id}))
  if(axiosRes.status !== 200){
    throw new Error("" + axiosRes.status)
  }
  const response = axiosRes.data as HttpResponse<PaymentCheckoutSessionDTO>;
  checkCode(response)

  return response.data
  
}

const createPortalSession = async ():Promise<PaymentPortalSessionDTO> => {
  const axiosRes = (await axios.get(`/api/payment/create_portal_session`))
    if(axiosRes.status !== 200){
      throw new Error("" + axiosRes.status)
    }
    const response = axiosRes.data as HttpResponse<PaymentPortalSessionDTO>;
    checkCode(response)

    return response.data;
}
 
///api/payment/subscription
export default {
    subscription,
    plans,
    createCheckoutSession,
    createPortalSession
}