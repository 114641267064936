
import { ConfigProvider, Flex, Popover } from "antd";
import styles from "./Workbench.module.scss";

import { Outlet, useLocation, useNavigate, useNavigation } from "react-router-dom";
import { ReactNode, useContext, useEffect } from "react";
import Icon from "../components/icon/Icon";
import logoutSvg from "./assets/images/logout.svg"
import { UserContext } from "../login/context/UserContext";
import AuthFilter from "../login/AuthFilter";
import useToken from "../libs/useToken";
import loginApi from "../api/login.api";
import avatarPng from "./assets/images/Avatar.png";
import { useMask } from "../components/mask/Mask";
import ChoosePayment from "../payment/ChoosePayment";
import dayjs from "dayjs";
import { PaymentNotification, usePaymentNotification} from "../payment/PaymentNotification";
import useBroadcast from "../components/broadcast/useBroadcast";
import paymentApi from "../api/payment.api";
import PaymentTrialingEnd from "../payment/PaymentTrialingEnd";

export default () => {

    return (
        <AuthFilter>
            <Flex className={styles['workbench']}>
                <div className={styles['workbench__siderbar']}>
                    <WorkbeanchSiderBar/>
                </div>
                <div style={{flex: 1}} className={styles['workbench__container']}>
                    <Outlet/>
                </div>
            </Flex>
        </AuthFilter>
    )

}

const WorkbeanchSiderBar = () => {

    const navigate = useNavigate()
    const {user, subscription} = useContext(UserContext)
    const {removeToken} = useToken()
    const {render: maskRender, show, close} = useMask()
    const {render: broadcastRender, info, success, error} = useBroadcast()
    const {event, clean} = useContext(PaymentNotification)

    const logout = () => {
        
        loginApi.logout().then(() => {
            
        })
        removeToken()
        navigate("/")
    }

    const openPayment = () => {
        show(<ChoosePayment close={close}/>)
    }

    const toManageYourPlan = async () => {
        const session = await paymentApi.createPortalSession();
        if(session.portal_url){
            window.open(session.portal_url, "_blank")
        }
    }


    useEffect(() => {

        if(subscription){

            switch(subscription.status){
                case 'UNSUBSCRIBED':
                    show(<ChoosePayment/>)
                    break;
                case 'TRIALING':
                    let expiredAt = dayjs(subscription.current_period_end)
                    if(expiredAt.isBefore(dayjs())){
                        show(<PaymentTrialingEnd close={close}/>)
                    }else{
                        if(subscription.cancel_at_period_end){
                            success({
                                content: "Your plan has been canceled. You will retain access to premium features until the end of your free trial period.",
                                duration: 5
                            })
                        } 
                    }
                    break
                case 'ACTIVE':
                    let activeExpiredAt = dayjs(subscription.current_period_end)
                    if(activeExpiredAt.isBefore(dayjs())){
                        show(<ChoosePayment/>)
                    }else{
                        if(subscription.cancel_at_period_end){
                            success({
                                content: "Your plan has been canceled. You will retain access to premium features until the end of your current billing period.",
                                duration: 5
                            })
                        }
                    }
                    
                    break
                default:
                    // const now = dayjs()
                    // if(subscription?.current_period_end){
                    //     const expiredAt = dayjs(subscription.current_period_end)
                    //     if(expiredAt.isBefore(dayjs())){
                    //         show(<PaymentEnd close={close}/>)
                    //     }
                    // }
            }
        }

    }, [subscription])

    useEffect(() => {
        console.log("event: ", event)
        if(event){
            switch(event.level){
                case "info":
                    info({content: event.content, duration: 5})    
                    break
                case "error":
                    error({content: event.content, duration: 5})    
                    break
                case "success":
                    success({content: event.content, duration: 5})    
                    break
            }
        }
    }, [event])

    const renderTips = () => {
        
        if(subscription?.status === "TRIALING"){

            const expiredAt = dayjs(subscription.current_period_end)
            if(expiredAt.isAfter(dayjs())){
                const diff = Math.max(0, expiredAt.diff(dayjs(), "days") + 1 )
                const dateDesc = expiredAt.format("MMM D")
                return (
                    <div className={styles['siderbar__toolbox__payment__tips']}>
                        <div className={styles['siderbar__toolbox__payment__label']}>Subscription</div>
                        <div className={styles['siderbar__toolbox__payment__value']}>Trial ends in {diff} days on {dateDesc}</div>
                    </div>
                )
            }else{
                //Alert
                return <></>
            }
            
        }else if(subscription?.status === "ACTIVE"){
            
            const expiredAt = dayjs(subscription.current_period_end)
            if(expiredAt.isAfter(dayjs())){
                const diff = Math.max(0, expiredAt.diff(dayjs(), "days") + 1 )
                const dateDesc = expiredAt.format("YYYY/MM/DD")
                return (
                    <div className={styles['siderbar__toolbox__payment__tips']}>
                        <div className={styles['siderbar__toolbox__payment__label']}>Subscription</div>
                        <div className={styles['siderbar__toolbox__payment__value']}>Active {subscription.current_plan?.recurring_interval}ly subscription ends on {dateDesc}</div>
                    </div>
                )
            }else{
                return <></>
            }
        }
        
    }

    const renderPayment = () => {
        return (
            <div className={styles['siderbar__toolbox__payment']}>
                {renderTips()}
                {
                    subscription?.current_plan
                    ?
                    <div className={styles['siderbar__toolbox__payment__btn']} onClick={toManageYourPlan}>Manage your plan</div>    
                    :
                    <div className={styles['siderbar__toolbox__payment__choose']} onClick={openPayment}>Choose Plan</div>
                }
                
            </div>
        )
        
    }

    const ToolTip = (
        <Flex vertical align="center" className={styles['siderbar__toolbox']}>
            <div className={styles['siderbar__toolbox__tools']}>
                <div className={styles['siderbar__toolbox__profile']}>
                    <div className={styles['siderbar__toolbox__profile__name']}>{user?.last_name} {user?.first_name}</div>
                    <div className={styles['siderbar__toolbox__profile__email']}>{user?.email}</div>
                </div>
                <div className={styles['siderbar__toolbox__tools__divider']}></div>
                {renderPayment()}
            </div>
            
            <div className={styles['siderbar__toolbox__logout-wrapper']}>
                <Flex gap={8} align="center" className={styles['siderbar__toolbox__logout']}>
                    <Icon src={logoutSvg} size={16}></Icon>
                    <div style={{flex: 1}} className={styles['siderbar__toolbox__logout__label']} onClick={logout}>Log out</div>
                </Flex>
            </div>
        </Flex>
    )

    return (
        <Flex vertical justify="space-between" className={styles['siderbar']}>
            <Flex vertical align="center" gap={24}>
                <Popover placement="rightBottom" arrow={false} content={ToolTip}>
                    <div className={styles['siderbar__avatar']}>
                        <Icon src={avatarPng} size={48}></Icon>
                    </div>
                </Popover>
                <div className={styles['siderbar__divider']}></div>
                <MenuBar/>
            </Flex>
            {maskRender}
            {broadcastRender}
        </Flex> 
    )
}

const menus = [
    {
        path: "/workbench/chatbot",
        name: "chatbot"
    },
    {
        path: "/workbench/clientmanagement/client/home/0/0",
        name: "clientmanagement"
    },
    {
        path: "/workbench/smarttools",
        name: "smarttools"
    }
]

export interface MenuType{
    path: string
    name: string
}

const MenuBar = () => {

    const loc = useLocation()
    const navigate = useNavigate()

    const activate = (menu: MenuType) => {
        return loc.pathname == menu.path
    }

    const menuRender = (menu: MenuType) => {
        
        return (
            <div 
                className={[styles['menubar__item'], activate(menu)?styles['menubar__item_activate']:''].join(" ")}
                onClick={() => navigate(menu.path)}
            >
                <div className={[
                    styles['menubar__item__icon'], styles[`menubar__item__icon_${menu.name}`], activate(menu)?styles[`menubar__item__icon_${menu.name}_activate`]:''].join(" ")}></div>
            </div>
        )
    }

    return (
        <Flex vertical align="center" gap={16}>
            {menus.map(menu => menuRender(menu))}
        </Flex>
    )

}