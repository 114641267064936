import { Route, Routes } from 'react-router-dom';
import './App.css';
import Workbench from './pages1/workbench/Workbench';
import Homepage from './pages1/homepage/Homepage';
import Chatbot from './pages1/chatbot/Chatbot';
import ClientManagement from './pages1/clientmanagement/ClientManagement';
import Login from './pages1/login/Login';
import Signup from './pages1/signup/Signup';
import SignupFirst from './pages1/signup/SignupFirst';
import SignupTellUsMore from './pages1/signup/SignupTellUsMore';
import SignupWaitingList from './pages1/signup/SignupWaitingList';
import SignupVerifyEmail from './pages1/signup/SignupVerifyEmail';
import ForgetPasswordCheckEmail from './pages1/forgetpassword/ForgetPasswordCheckEmail';
import ForgetPasswordReset from './pages1/forgetpassword/ForgetPasswordReset';
import ForgetPasswordApply from './pages1/forgetpassword/ForgetPasswordApply';
import ForgetPassword from './pages1/forgetpassword/ForgetPassword';
import useBroadcast from './pages1/components/broadcast/useBroadcast';
import { BroadcastContext } from './pages1/components/broadcast/BroadcastContext';
import ChatbotV2 from './pages1/chatbot/ChatbotV2';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { RegisterParams, SignupContext } from './pages1/signup/context/SignupContext';
import { useContext, useState } from 'react';
import SmartTools from './pages1/smarttools/SmartTools';
import SmartToolsDemo from './pages1/smarttools/SmartToolsDemo';
import { PaymentFailNotification, PaymentSuccessNotification, usePaymentNotification, PaymentNotification} from './pages1/payment/PaymentNotification';

function App() {

  const broadcast = useBroadcast()
  const [register, setRegister] = useState<RegisterParams>()
  const paymentNotification = usePaymentNotification()
  

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Homepage />,
    },
    {
      path: "/workbench",
      element: <Workbench/>,
      children: [
        {
          path: "chatbot",
          element: <ChatbotV2 />
        },
        {
          id: 'clientmanagement',
          path: "clientmanagement/:clientId/:caseId/:module/:moduleId",
          element: <ClientManagement />,
          loader: ({params}) => {
            return {caseId: params['caseId'], clientId: params['clientId'], module: params['module'], moduleId: params['moduleId']}
          } 
        },
        {
          path: "smarttools",
          element: <SmartTools />
        },
      ]
    },
    {
      path: "/login",
      element: <SignupContext.Provider value={{register, setRegister}}>
                <Login/>
            </SignupContext.Provider>
    },
    {
      path: "/signup",
      element: <SignupContext.Provider value={{register, setRegister}}><Signup/></SignupContext.Provider>,
      children:[
        {
          path: "first",
          element: <SignupFirst/>
        },
        {
          path: "tell_us_more",
          element: <SignupTellUsMore/>
        },
        {
          path: "verify_email",
          element: <SignupVerifyEmail/>
        }
      ]
    },
    {
      path: "/addedToQueue",
      element: <SignupWaitingList/>
    },
    {
      path: "/forgetPassword",
      element: <ForgetPassword/>,
      children: [
        {
          path: "apply",
          element: <ForgetPasswordApply/>
        },
        {
          path: "checkEmail",
          element: <ForgetPasswordCheckEmail/>
        }
      ]
    },
    {
      path: "reset_password",
      element: <ForgetPasswordReset/>
    },
    {
      path: "/notification/payment/success",
      element: <PaymentSuccessNotification/>
    },
    {
      path: "/notification/payment/fail",
      element: <PaymentFailNotification/>
    }
  ]);

  return (
    <PaymentNotification.Provider value={paymentNotification}>
      <BroadcastContext.Provider value={broadcast}>
        <RouterProvider router={router} />
        {broadcast.render}
      </BroadcastContext.Provider>
    </PaymentNotification.Provider>
    
  );
}

export default App;
