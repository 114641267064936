
import { useContext, useEffect, useState } from "react"
import styles from "./PaymentEnd.module.scss"
import { UserContext } from "../login/context/UserContext"
import paymentApi from "../api/payment.api"
import Button from "../components/Button"

export default (props: {close: () => void}) => {

    
    const {subscription} = useContext(UserContext)
    const [loading, setLoading] = useState<boolean>()
    const {
        close
    } = props

    const renderAmount = () => {
        const unit = subscription?.current_plan?.currency === "usd"?"$":"?"
        return `${unit}${subscription?.current_plan?.unit_amount}`
    }

    const doPay = async () => {
    
            if(loading) return
    
            setLoading(true)
            try{
                const session = await paymentApi.createPortalSession();
                if(session.portal_url){
                    window.open(session.portal_url, "_blank")
                }
            }finally{
                setLoading(false)
            }
            
        }


    return (
        <div className={styles['payment-end-wrapper']}>
            <div className={styles['payment-end']}>
                <div className={styles['payment-end__body']}>
                    <div className={styles['payment-end__title']}>
                    Your {subscription?.current_plan?.free_trial_days??30}-day free trial has ended
                    </div>
                    <div className={styles['payment-end__subtitle']}>
                        Your {subscription?.current_plan?.free_trial_days??30}-day free trial has ended. You previously selected the {renderAmount()} {subscription?.current_plan?.recurring_interval}ly plan. To continue using the service, please add a payment method.
                    </div>
                </div>
                <div className={styles['payment-end__footer']}>
                    <div className={[styles['payment-end__btn_cancel'], styles['payment-end__btn']].join(" ")} onClick={close}>Cancel</div>
                    <Button.Primary width={"100%"} className={[styles['payment-end__btn_confirm'], styles['payment-end__btn']].join(" ")} onClick={doPay} loading={loading}>Add payment method</Button.Primary>
                </div>
            </div>
        </div>
    )
}