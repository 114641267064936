import { createContext, useContext } from "react";
import { GuideTourStatus, UserDTO } from "../../api/login.api";
import { SubscriptionDTO } from "../../api/payment.api";

export interface UserContextData {
    user: UserDTO | undefined
    setUser: (user: UserDTO | undefined) => void
    changeTourStatus: (status: GuideTourStatus, flag: boolean) => void
    subscription?: SubscriptionDTO
}

export const UserContext = createContext<UserContextData>({
    user: undefined,
    subscription: undefined,
    setUser(user) {
        throw new Error("unimplement");
    },
    changeTourStatus: function (status: GuideTourStatus, flag: boolean) {
        throw new Error("Function not implemented.");
    },
    
})