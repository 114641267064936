import { ReactNode } from "react"
import { Exhibit, Section } from "../smarttools.api"


export interface SmartArticleDTO{
    sections: Section[]
    exhibits: Exhibit[]
    
}

const markdownToHtml = (body: string) => {
    let texts: ReactNode[] = []
    let stream = ""
    let pairs = 0
    for(let token of body){
        stream += token
        if(stream.endsWith("**")){
            pairs++
        }
        if(pairs == 2){
            const splittedAt = stream.indexOf("**")
            texts.push(stream.slice(0, splittedAt))
            texts.push(<label style={{textDecoration: "underline"}}><strong>{stream.slice(splittedAt).slice(2, -2)}</strong></label>)

            stream = "";
            pairs = 0
        }
    }
    texts.push(stream)
    return texts.filter(text => text && text !== "")
}


export default (props: {article: SmartArticleDTO}) => {

    const {
        article
    } = props
    
    const {
        sections,
        exhibits
    } = article

    return (
        <article>
            <section>
                {
                    sections.map(section => {
                        return <>
                            <p>{section.criteria}</p>
                            <h3>{section.section_title}</h3>
                            {
                                section.section_type === 'text'
                                &&
                                <p>{markdownToHtml(section.content)}</p>
                            }
                            {
                                section.section_type === 'image'
                                &&
                                <img src={section.url}></img>
                            }
                            
                        </>
                    })
                }
            </section>
            {
                (exhibits && exhibits.length > 0) && 
                <section>
                    <h3>Appendix - Exhibit List</h3>
                    {
                        <ul>
                            {
                                exhibits.map(exhibit => {
                                    return (
                                        <li><strong>{exhibit.label}: </strong>{exhibit.text} {exhibit.sources.map(source => <><label>-</label><a href={source.url}>{source.url_text}</a></>)}</li>
                                    )
                                })
                            }
                        </ul>
                        
                    }
                </section>  
            }
        </article>
    )
}

export const SmartArticleWithImage =(props: {article: SmartArticleDTO}) => {

    const {
        article
    } = props
    
    const {
        sections,
        exhibits
    } = article

    return (
        <article>
            <section>
                {
                    sections.map(section => {
                        return <>
                            <p>{section.criteria}</p>
                            <h3>{section.section_title}</h3>
                            {
                                section.section_type === 'text'
                                &&
                                <p>{markdownToHtml(section.content)}</p>
                            }
                            {
                                section.section_type === 'image'
                                &&
                                <img src={section.url}></img>
                            }
                            
                        </>
                    })
                }
            </section>
            {
                (exhibits && exhibits.length > 0) && 
                <section>
                    <h3>Appendix - Exhibit List</h3>
                    {
                        <ul>
                            {
                                exhibits.map(exhibit => {
                                    return (
                                        <li><strong>{exhibit.label}: </strong>{exhibit.text} {exhibit.sources.map(source => <><label>-</label><a href={source.url}>{source.url_text}</a></>)}</li>
                                    )
                                })
                            }
                        </ul>
                        
                    }
                </section>  
            }
        </article>
    )
}