import { createContext, useContext, useEffect, useState } from "react"
import paymentApi from "../api/payment.api"
import { useNavigate } from "react-router-dom"
import LoadingIcon from "../components/icon/LoadingIcon"

export type PaymentNotificationEventLevel = "info" | "error" | "success"

export interface PaymentNotificationEvent{
    level: PaymentNotificationEventLevel
    content: string
}

export interface PaymentNotificationProps{
    event?: PaymentNotificationEvent
    addEvent: (event: PaymentNotificationEvent) => void
    clean?: () => void
}

export const PaymentNotification = createContext<PaymentNotificationProps>({
    addEvent: function (event: PaymentNotificationEvent): void {
        throw new Error("Function not implemented.")
    }
})

export const usePaymentNotification = () => {
    
    const [event, setEvent] = useState<PaymentNotificationEvent>()

    const addEvent: PaymentNotificationProps['addEvent'] = (event) => {
        setEvent(event)
    }

    const clean: PaymentNotificationProps['clean'] = () => {
        setEvent(undefined)
    }

    return {
        event,
        addEvent,
        clean
    }
}


export const PaymentSuccessNotification = () => {

    const navigate = useNavigate()
    const {addEvent, event} = useContext(PaymentNotification)
    useEffect(() => {

        (async () => {

            let sub = await paymentApi.subscription()
            const plan = sub.current_plan
            if(!plan){
                addEvent({
                    level: "success",
                    content: "No charges until your trial ends."
                })
            }else{
                addEvent({
                    level: "success",
                    content: "Payment method set up! No charges until your trial ends."
                })
            }

            // setTimeout(() => {
            //     navigate("/workbench/chatbot")
            // }, 0);

        })()
        
    }, [])

    useEffect(() => {
        event && navigate("/workbench/chatbot")
    }, [event])
    
    return <LoadingIcon/>

}


export const PaymentFailNotification = () => {

    const navigate = useNavigate()
    const {addEvent} = useContext(PaymentNotification)
    useEffect(() => {
        addEvent({
            level: "error",
            content: "No charges until your trial ends."
        })
        setTimeout(() => {
            navigate("/")
        }, 2000);
        
    }, [])
    
    return <LoadingIcon/>
}